export default {
    "HOST_NAME": "onedex.io",
    "DOMAIN_URL": "https://subdomain.onedex.io/dex",
    "DEFAULT_CHAIN_ID": 10,
    "CHAINS_NAMES_SUPPORTED": process.env.REACT_APP_IS_TESTING ?
        [
            "Optimistic"
        ] :
        [
            "Optimistic"
        ]
    ,
    "CHAINS_NAMES_MAPPING": {
        10: "Optimistic"
    },
    "SUPPORTED_CHAIN_IDS": process.env.REACT_APP_IS_TESTING ?
        {
            "Optimistic": 10
        } :
        {
            "Optimistic": 10
        },
    "XGMT_EXCLUDED_ACCOUNTS": [
        "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
        "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
        "0xa633158288520807f91ccc98aa58e0ea43acb400",
        "0xffd0a93b4362052a336a7b22494f1b77018dd34b"
    ],
    "GMX_STATS_API_URL": "https://stats.gmx.io/api",
    "ALCHEMY_WHITELISTED_DOMAINS": [
        "gmx.io",
        "app.gmx.io"
    ],
    "STAKE_NAMES_CONTRACT": [
        "GMX",
        "RewardRouter",
        "esGMX",
        "bnGMX",
        "StakedGmxTracker",
        "StakedGmxDistributor",
        "BonusGmxTracker",
        "BonusGmxDistributor",
        "FeeGmxTracker",
        "FeeGmxDistributor",
        "FeeGlpTracker",
        "FeeGlpDistributor",
        "StakedGlpTracker",
        "StakedGlpDistributor",
        "GmxVester",
        "GlpVester",
        "Commissions"
    ],
    "GLP_POOL_COLORS": {
        "ETH": "#6062a6",
        "BTC": "#F7931A",
        "BTC.b": "#F7931A",
        "USDC": "#2775CA",
        "USDC.e": "#2A5ADA",
        "USDT": "#67B18A",
        "MIM": "#9695F8",
        "FRAX": "#000",
        "DAI": "#FAC044",
        "UNI": "#E9167C",
        "AVAX": "#E84142",
        "LINK": "#3256D6",
        "OP": "#3256D6"
    },
    "CHAINS": {
        // "1337": {
        //     "chainName": "Localhost-TEST",
        //     "nativeCurrency": {
        //         "name": "ETH",
        //         "symbol": "ETH",
        //         "decimals": 18
        //     },
        //     "MULTIPLIER": 65000,
        //     "ExplorerUrl": "https://bscscan.com/",
        //     "RPC_PROVIDERS": [
        //         "https://test-node.onedex.io/"
        //     ],
        //     "FALLBACK_PROVIDERS": [
        //         "https://test-node.onedex.io/"
        //     ],
        //     "GAS_PRICE_ADJUSTMENT": "0",
        //     "MAX_GAS_PRICE": "200000000000",
        //     "HIGH_EXECUTION_FEES": 3,
        //     "nativeTokenSymbol": "ETH",
        //     "wrappedTokenSymbol": "WETH",
        //     "defaultCollateralSymbol": "USDT",
        //     "defaultFlagOrdersEnabled": false,
        //     "positionReaderPropsLength": 9,
        //     "v2": true,
        //     "SWAP_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
        //     "INCREASE_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
        //     "DECREASE_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
        //     "BACKEND_URLS": "https://test-api.onedex.io/dex",
        //     "BACKEND_CORS_PROXY": "https://any-cors.onedex.io",
        //     "CANDLE_API_URLS": "https://stats.gmx.io/api/candles",
        //     "SHARE_URL": "https://test-share.onedex.io",
        //     "SUBGRAPH_URLS": {
        //         "stats": "https://test-thegraph.onedex.io/subgraphs/name/onedex/localtest-stats",
        //         "referrals": "https://test-thegraph.onedex.io/subgraphs/name/onedex/localtest-referrals",
        //         "vault": "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
        //         "chainLink": "https://api.thegraph.com/subgraphs/name/deividask/chainlink"
        //     },
        //     "PLATFORM_CONTRACTS": {
        //         "WalletFactory": "0xa513E6E4b8f2a923D98304ec87F64353C4D5C853",
        //         "NATIVE_TOKEN": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        //         "ReferralReader": "0x610178dA211FEF7D417bC0e6FeD39F05609AD788",
        //         "ReferralStorage": "0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e",
        //         "Vault": "0x0DCd1Bf9A1b36cE34237eEaFef220932846BCD82",
        //         "VaultTokens": "0x9A676e781A523b5d0C0e43731313A708CB607508",
        //         "VaultFees": "0x0B306BF915C4d645ff596e518fAf3F9669b97016",
        //         "BranchStorage": "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
        //         "USDG": "0x9A9f2CCfdE556A7E9Ff0848998Aa4a0CFD8863AE",
        //         "Router": "0x68B1D87F95878fE05B998F19b66F4baba5De1aed",
        //         "GLP": "0xc6e7DF5E7b4f2A278906862b61205850344D4e7d",
        //         "GlpManager": "0x59b670e9fA9D0A427751Af201D676719a970857b",
        //         "OrderBook": "0x322813Fd9A801c5507c9de605d63CEA4f2CE6c44",
        //         "StakingFactory": "0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f",
        //         "OrderBookReader": "0x70e0bA845a1A0F2DA3359C97E0285013525FFC49",
        //         "Reader": "0x4826533B4897376654Bb4d4AD88B7faFD0C98528",
        //         "RewardReader": "0x99bbA657f2BbC93c02D617f8bA121cB8Fc104Acf",
        //         "VaultReader": "0x0E801D84Fa97b50751Dbf25036d067dCf18858bF",
        //         "ES_GMX_IOU": "0x0000000000000000000000000000000000000000",
        //         "StakedGlpTracker": "0x0000000000000000000000000000000000000000",
        //         "OrderExecutor": "0x0000000000000000000000000000000000000000",
        //         "PositionRouter": "0x0000000000000000000000000000000000000000",
        //         "PositionManager": "0x0000000000000000000000000000000000000000",
        //         "Commissions": "0x0000000000000000000000000000000000000000",
        //         "BTC": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        //         "USDT": "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        //         "WETH": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6"
        //     },
        //     "GENERAL_CONTRACTS": {
        //         "WETH": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        //         "ETH": "0x0000000000000000000000000000000000000000",
        //         "BTC": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        //         "USDT": "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        //         "NATIVE_TOKEN": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        //         "UniswapGmxEthPool": "0x0000000000000000000000000000000000000000",
        //         "Multicall": "0xCace1b78160AE76398F486c8a18044da0d66d86D"
        //     },
        //     "TOKENS": [
        //         {
        //             "name": "USDT",
        //             "symbol": "USDT",
        //             "isStable": true,
        //             "decimals": 18
        //         },
        //         {
        //             "name": "BTC",
        //             "symbol": "BTC",
        //             "decimals": 18,
        //             "isShortable": true
        //         },
        //         {
        //             "name": "Ethereum",
        //             "symbol": "ETH",
        //             "decimals": 18,
        //             "isNative": true,
        //             "address": "0x0000000000000000000000000000000000000000",
        //             "isShortable": true,
        //             "imageUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"
        //         },
        //         {
        //             "name": "Wrapped Ethereum",
        //             "symbol": "WETH",
        //             "decimals": 18,
        //             "isWrapped": true,
        //             "baseSymbol": "ETH",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        //         }
        //     ],
        //     "COMMISSIONS_WHITELIST_TOKEN": [
        //         {
        //             "name": "Wrapped Ethereum",
        //             "symbol": "WETH",
        //             "address": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        //             "decimals": 18,
        //             "baseSymbol": "ETH",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        //         },
        //         {
        //             "name": "USDT",
        //             "symbol": "USDT",
        //             "decimals": 18,
        //             "address": "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663"
        //         }
        //     ],
        //     "BRANCH_WHITELIST_TOKEN": [
        //         {
        //             "name": "Wrapped Ethereum",
        //             "symbol": "WETH",
        //             "address": "0x2279B7A0a67DB372996a5FaB50D91eAA73d2eBe6",
        //             "decimals": 18,
        //             "baseSymbol": "ETH",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
        //         },
        //         {
        //             "name": "Bitcoin",
        //             "symbol": "BTC",
        //             "address": "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0",
        //             "decimals": 18,
        //             "baseSymbol": "BTC",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579"
        //         },
        //         {
        //             "name": "USDT",
        //             "symbol": "USDT",
        //             "decimals": 18,
        //             "address": "0x5FC8d32690cc91D4c39d9d3abcBD16989F875707",
        //             "imageUrl": "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663"
        //         }
        //     ],
        //     "PLATFORM_TOKENS": [
        //         {
        //             "name": "GMX",
        //             "symbol": "GMX",
        //             "decimals": 18,
        //             "imageUrl": "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468"
        //         },
        //         {
        //             "name": "Escrowed GMX",
        //             "symbol": "esGMX",
        //             "decimals": 18
        //         },
        //         {
        //             "name": "GMX LP",
        //             "symbol": "GLP",
        //             "decimals": 18,
        //             "imageUrl": "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true"
        //         }
        //     ]
        // },
        "10": {
            "chainName": "Optimistic",
            "nativeCurrency": {
                "name": "ETH",
                "symbol": "ETH",
                "decimals": 18
            },
            "MULTIPLIER": 65000,
            "ExplorerUrl": "https://optimistic.etherscan.io/",
            "RPC_PROVIDERS": [
                "https://optimism-mainnet.public.blastapi.io/"
            ],
            "FALLBACK_PROVIDERS": [
                // "https://cool-few-energy.optimism.quiknode.pro/b8d07afffb5bc6a0e2489769aca65c3c0f4b48be/",
                "https://1rpc.io/op",
                "https://rpc.ankr.com/optimism"
            ],
            "GAS_PRICE_ADJUSTMENT": "0",
            "MAX_GAS_PRICE": "200000000000",
            "HIGH_EXECUTION_FEES": 3,
            "nativeTokenSymbol": "ETH",
            "wrappedTokenSymbol": "WETH",
            "defaultCollateralSymbol": "USDT",
            "defaultFlagOrdersEnabled": false,
            "positionReaderPropsLength": 9,
            "v2": true,
            "SWAP_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
            "INCREASE_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
            "DECREASE_ORDER_EXECUTION_GAS_FEE": "13000000000000000",
            "BACKEND_URLS": "https://api.onedex.io/dex",
            "BACKEND_CORS_PROXY": "https://any-cors.onedex.io",
            "CANDLE_API_URLS": "https://stats.gmx.io/api/candles",
            "SHARE_URL": "https://share.onedex.io",
            "SUBGRAPH_URLS": {
                "stats": "https://api.thegraph.com/subgraphs/name/onedexdev/onedex-op-stats",
                "referrals": "https://api.thegraph.com/subgraphs/name/onedexdev/onedex-op-referrals",
                "vault": "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
                "chainLink": "https://api.thegraph.com/subgraphs/name/deividask/chainlink"
            },
            "PLATFORM_CONTRACTS": {
                "WalletFactory": "0x61625aa3a9769b6424aec2f041C34F57739195A9",
                "ReferralReader": "0xf7793B06CF6F77C8f3122DebdDECf863421F96a4",
                "ReferralStorage": "0xB70546D196102a48F37DCa9F10c56417D6dbeE82",
                "Vault": "0x19DF5D9F4476d3D65ECcb68DA43196e46c8A4f5c",
                "VaultTokens": "0x7D7FCf4992df92eae2130d710C9d54E35c395eA7",
                "VaultFees": "0x2ABC3260616CC1ba48ede675fC3019252E6e0552",
                "BranchStorage": "0x0B34F1cE2c4C4E0106eE7557F13293F13F6b6f91",
                "USDG": "0x268c7334929423A81dC969479Bf458Bdb37B5Dee",
                "Router": "0x44f80cA1e59e392c135deafe426c14889A878a14",
                "GLP": "0xcE8D1B1aa42c28dF7291E01dA865b21e885D9768",
                "GlpManager": "0x3d92570fFFAFBCFDe63A152b78869Cb7FEe9a7C6",
                "OrderBook": "0x223a50450b0831FdC1c3C8f79F4B47AFaa57cF3d",
                "StakingFactory": "0xF39924E539851e52A38A2752381d9f82af46496B",
                "OrderBookReader": "0x7E6DEE5F4B473C2B7e73dA72B40BA9fc754BCaaE",
                "Reader": "0x70dEB28Cc702467C92BF779a2D80f8161B0B9997",
                "RewardReader": "0xAB6a879Fd3a39A285cB3697b376beC8760Fc4561",
                "VaultReader": "0xb193cdD81978db27ab50A7F2353645Ca35c4655B",
                "ES_GMX_IOU": "0x0000000000000000000000000000000000000000",
                "OrderExecutor": "0x0000000000000000000000000000000000000000",
                "PositionRouter": "0x0000000000000000000000000000000000000000",
                "PositionManager": "0x0000000000000000000000000000000000000000"
            },
            "GENERAL_CONTRACTS": {
                "WETH": "0x4200000000000000000000000000000000000006",
                "ETH": "0x0000000000000000000000000000000000000000",
                "BTC": "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
                "OP": "0x4200000000000000000000000000000000000042",
                "USDT": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
                "NATIVE_TOKEN": "0x4200000000000000000000000000000000000006",
                "UniswapGmxEthPool": "0x0000000000000000000000000000000000000000",
                "Multicall": "0xcA11bde05977b3631167028862bE2a173976CA11"
            },
            "TOKENS": [
                {
                    "name": "Tether (USDT)",
                    "symbol": "USDT",
                    "isStable": true,
                    "decimals": 6,
                    "imageUrl": "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663"
                },
                {
                    "name": "Bitcoin",
                    "symbol": "BTC",
                    "decimals": 8,
                    "isShortable": true,
                    "imageUrl": "https://assets.coingecko.com/coins/images/1/small/bitcoin.png?1547033579"
                },
                {
                    "name": "Optimism",
                    "symbol": "OP",
                    "decimals": 18,
                    "isShortable": true,
                    "imageUrl": "https://assets.coingecko.com/coins/images/25244/small/Optimism.png?1660904599"
                },
                {
                    "name": "Ethereum",
                    "symbol": "ETH",
                    "decimals": 18,
                    "isNative": true,
                    "address": "0x0000000000000000000000000000000000000000",
                    "isShortable": true,
                    "imageUrl": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"
                },
                {
                    "name": "Wrapped Ethereum",
                    "symbol": "WETH",
                    "decimals": 18,
                    "isWrapped": true,
                    "baseSymbol": "ETH",
                    "imageUrl": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
                }
            ],
            "COMMISSIONS_WHITELIST_TOKEN": [
                {
                    "name": "Tether (USDT)",
                    "symbol": "USDT",
                    "decimals": 6,
                    "address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
                    "imageUrl": "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663"
                }
            ],
            "BRANCH_WHITELIST_TOKEN": [
                {
                    "name": "Wrapped Ethereum",
                    "symbol": "WETH",
                    "address": "0x4200000000000000000000000000000000000006",
                    "decimals": 18,
                    "baseSymbol": "ETH",
                    "imageUrl": "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295"
                },
                {
                    "name": "Tether (USDT)",
                    "symbol": "USDT",
                    "decimals": 6,
                    "address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
                    "imageUrl": "https://assets.coingecko.com/coins/images/325/small/Tether.png?1668148663"
                }
            ],
            "PLATFORM_TOKENS": [
                {
                    "name": "GMX",
                    "symbol": "GMX",
                    "decimals": 18,
                    "imageUrl": "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468"
                },
                {
                    "name": "Escrowed GMX",
                    "symbol": "esGMX",
                    "decimals": 18
                },
                {
                    "name": "GMX LP",
                    "symbol": "GLP",
                    "decimals": 18,
                    "imageUrl": "https://github.com/gmx-io/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true"
                }
            ]
        }
    }
}