import { gql } from "@apollo/client";
import { BigNumber, ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { groupBy } from "lodash";

import { getContract } from "../config/contracts";

import branchStorageAbi from "../abis/BranchStorage.json";
import vaultFeesAbi from "../abis/VaultFees.json";
import { callContract, contractFetcher } from "lib/contracts";
import { getGmxGraphClient, TYPE } from "../lib/subgraph";
import { bigNumberify } from "../lib/numbers";
import { getAddress } from "ethers/lib/utils";
import { useBranchId } from "./legacy";
import { getServerDomainUrl } from "config/backend";

function getGraphClient(chainId) {
  let graphClient = getGmxGraphClient(chainId, TYPE.STATS)
  if (graphClient) return graphClient
  throw new Error(`Unsupported chain ${chainId}`);
}
export async function getBranchDataFromStats(chainId, branchId) {
  if (!chainId || branchId == null)
    return {
      branchStats: [],
      branchFeesRecords: [],
      branchFeesAvailable: {},
    };
  const query = gql`
    query referralData($branchId: Int!) {
      branchStats(where: { period: total, branchId: $branchId }) {
        volume
        branchId
        tradedMembersCount
      }
      branchFeesStats(where: { branchId: $branchId, period: total }) {
        token
        amount
        amountCumulative
      }
      branchFeesRecords(where: { typeId: 1, branchId: $branchId }) {
        amount
        token
        typeId
        transactionHash,
        timestamp
      }
    }
  `;
  function prepareStatsItem(e) {
    return {
      volume: bigNumberify(e.volume),
      tradedMembersCount: bigNumberify(e.tradedMembersCount),
    };
  }
  try {
    let branchFeesAvailable = {};
    let res = await getGraphClient(chainId).query({
      query,
      variables: {
        branchId: branchId ?? 0,
      },
      fetchPolicy: "no-cache",
    });
    let branchStats = res.data.branchStats.map(prepareStatsItem);

    let branchFeesRecordsByToken = groupBy(res.data.branchFeesRecords, "token");

    let branchFeeTokens = {}

    res.data.branchFeesStats.forEach(({ token, amount }) => {
      branchFeeTokens[token] = token
    });

    let tokens = Object.keys(branchFeeTokens)
    let branchFeeReservesTask = tokens.map(token => {
      let VaultFees = getContract(chainId, 'VaultFees')
      let contractCall = contractFetcher(undefined, vaultFeesAbi, [])
      return contractCall(`VaultFees.branchFeeReserves`, chainId, VaultFees, 'branchFeeReserves', branchId, token)
    })
    let branchFeeReserves = await Promise.all(branchFeeReservesTask)
    branchFeeReserves.map((avai, i) => {
      branchFeesAvailable[tokens[i]] = {
        token: tokens[i],
        amount: avai,
      }
    })

    return {
      branchStats: branchStats || [],
      branchFeesStats: res.data.branchFeesStats || [],
      branchFeesRecords: res.data.branchFeesRecords || [],
      branchFeesAvailable: branchFeesAvailable,
    };
  } catch (error) {
    console.warn(error);
    return {
      branchStats: [],
      branchFeesStats: [],
      branchFeesRecords: [],
      branchFeesAvailable: {},
    };
  }
}
export function useBranchData(chainId) {
  let [data, setData] = useState({
    branchStats: [],
    branchFeesStats: [],
    branchFeesRecords: [],
    branchFeesAvailable: {},
  });
  let branchId = useBranchId()
  const refresh = useCallback(() => {
    branchId != null && chainId &&
      getBranchDataFromStats(chainId)
        .then((res) => {
          setData(res);
        })
        .catch(console.warn);
  }, [chainId, branchId]);
  useEffect(() => {
    if (!chainId) return;
    branchId != null && getBranchDataFromStats(chainId)
      .then((res) => {
        setData(res);
      })
      .catch(console.warn);
  }, [chainId, branchId]);

  return { data, refresh };
}

export function useOwnerOfBranch(library, chainId, account, branchId) {
  const [owner, setOwner] = useState(null);
  const [isOwnerOfBanrch, setIsOwnerOfBranch] = useState(false);
  useEffect(() => {
    if (!library || !account || branchId == null) return;
    const BranchStorageAddress = getContract(chainId, "BranchStorage");
    const BranchStorageContract = new ethers.Contract(BranchStorageAddress, branchStorageAbi.abi, library);
    BranchStorageContract.ownerOf(branchId)
      .then((res) => {
        setOwner(res);
        if (getAddress(res) === getAddress(account)) {
          setIsOwnerOfBranch(true)
        } else {
          setIsOwnerOfBranch(false)
        }
      })
      .catch(console.warn);
  }, [account, library, chainId]);
  return [owner, isOwnerOfBanrch];
}

export function useClaimFees(library, chainId, account, branchId, setPendingTxns) {
  const [loading, setLoading] = useState(false);

  const handleClaim = useCallback(
    async (token) => {
      if (!library || !account || !token || branchId == null) return;
      const VaultFeesAddress = getContract(chainId, "VaultFees");
      const VaultContract = new ethers.Contract(VaultFeesAddress, vaultFeesAbi.abi, library.getSigner());
      setLoading(true);
      callContract(chainId, VaultContract, "withdrawFees", [branchId, token, account], {
        setPendingTxns,
        sentMsg: t`Claim submitted.`,
        successMsg: t`Requested claim confirmed.`,
        failMsg: t`Claim failed.`,
      }).then((tx) => {
        tx && tx.wait().then(() => {
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        })
      }).catch((e) => {
        setLoading(false);
      });
    },
    [account, chainId, library, branchId]
  );
  return { claim: handleClaim, loading };
}

export const useFetchDomain = (branchId, chainId) => {
  const [data, setData] = useState('')
  const fetchDomain = async () => {
    const beUrl = getServerDomainUrl()
    let res = await fetch(`${beUrl}/branchData?branchId=${branchId}&chainId=${chainId}`).then((response) => {
      if (!response.ok) throw new Error(response.status);
      else return response.json();
    })
    setData(res.data)
  }
  useEffect(() => {
    if (branchId == null) {
      return setData('')
    }
    fetchDomain()
  }, [branchId, chainId])

  return [data, data.subdomain]
}
