import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "../../config/subgraph";
import CONFIGS from '../../config/config'

export const chainlinkClient = createClient('https://api.thegraph.com/subgraphs/name/onedexdev/onedex-op-stats');

export enum TYPE {
  STATS = 'STATS',
  REFERRALS = "REFERRALS",
  VAULT = "VAULD"
}

export function getGmxGraphClient(chainId: number, type: TYPE = TYPE.STATS) {
  let graphStats = CONFIGS.CHAINS[chainId].SUBGRAPH_URLS['stats']
  let graphReferrals = CONFIGS.CHAINS[chainId].SUBGRAPH_URLS['referrals']
  let graphVault = CONFIGS.CHAINS[chainId].SUBGRAPH_URLS['vault']
  switch (type) {
    case TYPE.STATS:
      return createClient(graphStats)
    case TYPE.REFERRALS:
      return createClient(graphReferrals)
    case TYPE.VAULT:
      return createClient(graphVault)
    default:
      break;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
