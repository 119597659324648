import CONFIGS from './config'
import { PLATFORM_TOKENS, updateFlatformTokens } from './tokens';

export const XGMT_EXCLUDED_ACCOUNTS = CONFIGS.XGMT_EXCLUDED_ACCOUNTS;

const CONTRACTS = {};

for (const chainId in CONFIGS.CHAINS) {
  CONTRACTS[chainId] = {
    ...CONFIGS.CHAINS[chainId].PLATFORM_CONTRACTS,
    ...CONFIGS.CHAINS[chainId].GENERAL_CONTRACTS
  }
}

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}

export function getNativeToken(chainId: number) {
  let nativeTokenAddress = CONFIGS.CHAINS[chainId].GENERAL_CONTRACTS.NATIVE_TOKEN

  if (nativeTokenAddress) return nativeTokenAddress

  throw new Error(`Unknown contract "NATIVE_TOKEN" for chainId ${chainId}`);
}

export function updateContracts(chainId, name, address) {
  try {
    CONTRACTS[chainId][name] = address
  } catch (error) {
    throw new Error(`updateContracts ${chainId}:${name} ${error.toString()}`);
  }
}

