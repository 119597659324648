import { ACTIVE_CHAIN_IDS, CHAINS_NAMES_MAPPING, SUPPORTED_CHAIN_IDS } from "../../config/chains"
import { getContract, updateContracts } from "../../config/contracts"
import { contractFetcher } from "../../lib/contracts"
import { useEffect, useState } from "react"
import StakingFactory from '../../abis/StakingFactory.json'
import Token from '../../abis/Token.json'
import BranchStorage from '../../abis/BranchStorage.json'
import Commission from '../../abis/Commissions.json'
import { STAKE_NAMES_CONTRACT, updateFlatformTokens } from "../../config/tokens"
import { CONFIG_BRANCH, STAKING_SETS } from "../../config/localStorage"
import { useBranchId } from "../../domain/legacy"
import { BigNumber, ethers } from "ethers"
import { getTopicConfigs } from "config/ui"
import { useDispatch, useSelector } from "react-redux"
import { setCommissionContract, setMainTokenInfo } from "state/branch/reducer"
import { useChainId } from "lib/chains"
import { contractMulticall } from "lib/multicall/contractMulticallFetcher"
import { useAllCommissionInfo } from "state/branch/hooks"
import { helperToast } from "lib/helperToast"
import { JsonRpcProvider } from "@ethersproject/providers"
import { fetchBranchId, getBranchId } from "lib/legacy"

export const defaultConfig = {
    logo: "https://onedexdev.github.io/imgs/logo.svg",
    favicon: "https://onedexdev.github.io/imgs/logo.ico",
    sidebarLogo: "https://onedexdev.github.io/imgs/sidebar_logo.png",
    primary: "#fcd436",
    gradien_1: "#fcd436",
    gradien_2: "#fcd436",
    primary_text_btn_color: "#000000",
    socials: [
        // 'https://www.onedex.io/',// facebook
        // 'https://www.onedex.io/',// twitterIcon
        // 'https://www.onedex.io/',// telegramIcon
        // 'https://www.onedex.io/',// githubIcon
        // 'https://www.onedex.io/',// discordIcon
    ]
}

export const useReady = () => {
    const [ready, setReady] = useState(false)
    const { chainId } = useChainId()
    const branchIds = useAllBranchId()
    
    const stakingSetsAllChain = useStakingSetsAllChain(branchIds)
    const configAllChain = useConfigAllChain(branchIds);
    const allMainToken = useAllMainTokenInfo(stakingSetsAllChain as any);
    const allCommissionToken = useAllCommissionToken(branchIds)
    console.log('useReady', {
        branchIds,
        stakingSetsAllChain,
        configAllChain,
        allMainToken,
        allCommissionToken
    })
    const [needCreateBranch, setNeedCreateBranch] = useState(false);
    useEffect(() => {
        if (!stakingSetsAllChain || !configAllChain || !allMainToken.length || !allCommissionToken?.length) return setReady(false)
        setReady(true)
    }, [stakingSetsAllChain, configAllChain, allMainToken, allCommissionToken])
    useEffect(() => {

        try {
            //@ts-ignore
            if (stakingSetsAllChain?.length) {
                // Verify branchId have been create on all chain
                //@ts-ignore
                let stakingSetZero = stakingSetsAllChain.find(v => {
                    return v?.stakingSets.includes(ethers.constants.AddressZero)
                })
                if (stakingSetZero) {
                    //@ts-ignore
                    let networkName = CHAINS_NAMES_MAPPING[stakingSetZero.chainId]
                    helperToast.error(`You must create your dex on ${networkName} network`);
                    return setNeedCreateBranch(true)
                }
                //@ts-ignore
                let stakingInfo = stakingSetsAllChain?.find((v) => v.chainId == chainId);
                console.log({
                    chainId,
                    stakingInfo
                })
                if (!stakingInfo?.stakingSets?.length || (stakingInfo?.stakingSets?.length && stakingInfo?.stakingSets.includes(ethers.constants.AddressZero))) {
                    setNeedCreateBranch(true)
                }
            }
        } catch (error) {

        }
    }, [stakingSetsAllChain, chainId])
    return [ready, stakingSetsAllChain, configAllChain, needCreateBranch]
}
export const useNeedRedirectToNewBranch = () => {
    const branchId = useBranchId()
    const { chainId } = useChainId()

    const [needRedirect, setRedirect] = useState();
    useEffect(() => {

    }, [])
    return [needRedirect]
}

export const useAllCommissionToken = (branchIds: number[]) => {
    const dispatch = useDispatch()
    const data = useAllCommissionInfo()
    useEffect(() => {
        if (branchIds == null) return
        let tasks = ACTIVE_CHAIN_IDS.map((chainId) => {
            const branchStorageAddress = getContract(chainId, 'BranchStorage')
            const branchId = getBranchId(chainId)
            let contractCall = contractFetcher(undefined as any, BranchStorage, [])
            return contractCall(`BranchStorage:commContracts(${branchId})-${chainId}`, chainId, branchStorageAddress, 'commContracts', [branchId])
        }, [])
        Promise.all(tasks).then((res) => {
            let ret = res.map((contractAddress: any, i) => ({
                chainId: ACTIVE_CHAIN_IDS[i],
                commissionContractAddress: contractAddress
            }))
            dispatch(setCommissionContract(ret))
        })
    }, [branchIds])
    return data
}
export const useAllBranchId = () => {
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        let tasks = SUPPORTED_CHAIN_IDS.map(chainId => {
            return fetchBranchId(chainId)
        })
        Promise.all(tasks).then((branchIds) => {
            setData(branchIds)
        })
    }, [])
    return data
}
export const useAllMainTokenInfo = (stakingSetsAllChain?: {
    chainId: number,
    stakingSets: string[]
}[]) => {
    const dispatch = useDispatch()
    const allMainTokenInfo = useSelector(
        (state: any) => state?.branch?.mainToken || []
    )
    useEffect(() => {
        if (!stakingSetsAllChain || !stakingSetsAllChain.length) return;
        let tasks = stakingSetsAllChain.map(({ chainId, stakingSets }) => {
            let mainToken = stakingSets[0]
            let contractCall = contractMulticall(undefined as any, [Token.abi, Token.abi])
            return contractCall(`MainToken:symbol-decimals()${chainId}`, chainId, [mainToken, mainToken], ["symbol", "decimals"], [[], []])
        }, [])
        Promise.all(tasks).then((res) => {
            let ret = res.map((r: any, i) => {
                if (!r[0]) {
                    return {
                        chainId: stakingSetsAllChain[i].chainId,
                        symbol: '--',
                        decimal: '--'
                    }
                }
                let symbol = r[0][0]
                let decimals = r[1][0]
                return {
                    chainId: stakingSetsAllChain[i].chainId,
                    symbol: symbol,
                    decimal: decimals
                }
            })
            dispatch(setMainTokenInfo(ret))
        })
    }, [stakingSetsAllChain])
    return allMainTokenInfo;
}
export const useConfigAllChain = (branchIds: number[]) => {
    const [configAllChain, setConfigAllChain] = useState(null);
    useEffect(() => {

        branchIds != null && _fetchConfigByBranch().then(res => {
            setConfigAllChain(res as any)
        })
    }, [branchIds])
    return configAllChain
}
export const useStakingSets = (branchId, chainId) => {
    const [data, setData] = useState<string[]>([])
    useEffect(() => {
        if (branchId != null && chainId != null) {
            const stakingFactoryAddress = getContract(chainId, 'StakingFactory')
            let contractCall = contractFetcher(undefined as any, StakingFactory, [])
            contractCall(`StakingFacotry:stakingSets${chainId}`, chainId, stakingFactoryAddress, "stakingSets", branchId).then(stakingSets => {
                setData(stakingSets as string[])
            }).catch(console.error)
        }
    }, [branchId, chainId])
    return [data]
}

export const useStakingSetsAllChain = (branchIds: number[]) => {
    const [stakingSetsAllChain, setStakingSetsAllChain] = useState(null);

    useEffect(() => {
        branchIds != null && _fetchStakingSetsAllChain().then(res => {
            setStakingSetsAllChain(res as any)
        })
    }, [branchIds])
    return stakingSetsAllChain
}

async function _fetchStakingSetsAllChain() {
    let tasks = ACTIVE_CHAIN_IDS.map(chainId => {
        const stakingFactoryAddress = getContract(chainId, 'StakingFactory')
        const branchId = getBranchId(chainId)
        let contractCall = contractFetcher(undefined as any, StakingFactory, [])
        let stakingSetCaching = _readStakingSetsFromStorage(chainId, branchId)
        if (stakingSetCaching) {
            console.log('------USE STAKING SET FROM STORAGE------', {
                chainId,
                branchId,
                stakingSetCaching
            })
            return stakingSetCaching
        }
        console.info(`-----FETCH CONTRACT FROM ON CHAIN----- with branch ${branchId} ${chainId}`)
        return contractCall(`StakingFacotry:stakingSets${chainId}`, chainId, stakingFactoryAddress, "stakingSets", branchId)
    })
    try {
        let ret = await Promise.all(tasks).then(stakingSetss => {
            return ACTIVE_CHAIN_IDS.map((chainId, i) => {
                _cachingStakingSet(chainId, getBranchId(chainId), stakingSetss[i])
                return ({
                    chainId,
                    stakingSets: stakingSetss[i]
                })
            })
        })
        return ret
    } catch (error) {
        throw Error(error)
    }
}
export const fetchConfigOfBranch = async (chainId, branchId: number) => {
    let topic = getTopicConfigs()
    const branchStorageAddress = getContract(chainId, 'BranchStorage')
    let p = chainId === 10 ? new JsonRpcProvider("https://1rpc.io/op") : undefined
    let contractCall = contractFetcher(p as any, BranchStorage, [])
    let commisstionContractCall = contractFetcher(undefined as any, Commission, [])
    let configEncode = await contractCall(`branchStorageAddress:branchToppicVal(${branchId})${chainId}`, chainId, branchStorageAddress, "branchToppicVal", branchId, topic)
    console.log('configEncode fetchConfigOfBranch', configEncode, chainId, branchId)
    let commisstionContract = await contractCall(`branchStorageAddress:commContracts(${branchId})${chainId}`, chainId, branchStorageAddress, "commContracts", branchId)
    let commisstionToken = await commisstionContractCall(`commission.rewardToken`, chainId, commisstionContract, 'rewardToken')
    let basePercent = await contractCall(`branchStorageAddress:commBasePercents(${branchId})${chainId}`, chainId, branchStorageAddress, "commBasePercents", branchId)
    return {
        configs: _decodeConfig(configEncode as string),
        commisstionContract,
        basePercent,
        commisstionToken
    }
}
export const fetchFarmConfig = async (chainId, branchId): Promise<{
    gmxStakedDuration: BigNumber,
    glpStakedDuration: BigNumber,
    feeGmxStakedDuration: BigNumber,
    feeGlpStakedDuration: BigNumber,
    bonusMultiplier: BigNumber,
    vestingDurationGMX: BigNumber,
    vestingDurationGLP: BigNumber,
    mainTokenAddress: string

}> => {
    const stakingFactoryAddress = getContract(chainId, 'StakingFactory')
    let contractCall = contractFetcher(undefined as any, StakingFactory, [])
    let stakingInfos: any = await contractCall(`stakingFactory.getStakingInfo`, chainId, stakingFactoryAddress, 'getStakingInfo', branchId)
    let stakingSets: any = await contractCall(`stakingFactory.getStakingInfo`, chainId, stakingFactoryAddress, 'stakingSets', branchId)
    let keys = [
        'gmxStakedDuration',
        'glpStakedDuration',
        'feeGmxStakedDuration',
        'feeGlpStakedDuration',
        'bonusMultiplier',
        'vestingDurationGMX',
        'vestingDurationGLP'
    ]
    let result = {
        gmxStakedDuration: BigNumber.from(0),
        glpStakedDuration: BigNumber.from(0),
        feeGmxStakedDuration: BigNumber.from(0),
        feeGlpStakedDuration: BigNumber.from(0),
        bonusMultiplier: BigNumber.from(0),
        vestingDurationGMX: BigNumber.from(0),
        vestingDurationGLP: BigNumber.from(0),
        mainTokenAddress: stakingSets[0]
    }
    for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        result[key] = stakingInfos[index]
    }
    return result;

}
const _fetchConfigByBranch = async () => {
    let tasks = ACTIVE_CHAIN_IDS.map(chainId => {
        const branchStorageAddress = getContract(chainId, 'BranchStorage')
        let p = chainId === 10 ? new JsonRpcProvider("https://1rpc.io/op") : undefined
        let contractCall = contractFetcher(p as any, BranchStorage, [])
        let topic = getTopicConfigs()
        let branchId = getBranchId(chainId)
        return contractCall(`branchStorageAddress:branchToppicVal(${branchId})${chainId}`, chainId, branchStorageAddress, "branchToppicVal", branchId, topic)
    })
    try {
        let ret = await Promise.all(tasks).then(configs => {
            return ACTIVE_CHAIN_IDS.map((chainId, i) => {
                let branchId = getBranchId(chainId)
                let decodeConfig = _cachingConfig(chainId, branchId, configs[i])
                console.log(`config ${chainId} - ${branchId}`, decodeConfig, configs[i])
                return ({
                    chainId,
                    config: decodeConfig
                })
            })
        })
        return ret
    } catch (error) {
        console.warn(`Not found config of branch`, error?.message)
        // Return default theme
        return ACTIVE_CHAIN_IDS.map(chainId => {
            return {
                chainId,
                config: defaultConfig
            }
        })
    }
}
export const fetchConfigByBranch = _fetchConfigByBranch

const _readConfigFromStorage = (chainId, branchId) => {
    let config = JSON.parse(localStorage.getItem(JSON.stringify([chainId, branchId, CONFIG_BRANCH])) || 'null')
    return config
}
const _cachingConfig = (chainId, branchId, config, setCaching = true) => {
    const configDecode = _decodeConfig(config)
    // setCaching && localStorage.setItem(JSON.stringify([chainId, branchId, CONFIG_BRANCH]), JSON.stringify(configDecode))
    return configDecode
}
const _cachingStakingSet = (chainId, branchId, stakingSet, setCaching = true) => {
    setCaching && localStorage.setItem(JSON.stringify([chainId, branchId, STAKING_SETS]), JSON.stringify(stakingSet))
    STAKE_NAMES_CONTRACT.forEach((name, i) => {
        updateContracts(chainId, name, stakingSet[i]) // Update global variable CONTRACTS
    });
    updateFlatformTokens(chainId)
}
const _readStakingSetsFromStorage = (chainId, branchId) => {
    console.info('-----READ CONTRACT FROM STORAGE-----')
    try {
        let key = JSON.stringify([chainId, branchId, STAKING_SETS])
        let stakingSets = JSON.parse(localStorage.getItem(key) || 'null')

        if (stakingSets?.includes(ethers.constants.AddressZero)) {
            return null
        }

        return stakingSets
    } catch (error) {
        throw Error(`STAKING SETS INVALID ${error}`)
    }
}
const _decodeConfig = (configEncode: string) => {
    try {
        const configDecode = ethers.utils.defaultAbiCoder.decode(['string[]'], configEncode)
        return {
            logo: configDecode[0][0],
            sidebarLogo: configDecode[0][1],
            primary: configDecode[0][2],
            gradien_1: configDecode[0][3],
            gradien_2: configDecode[0][4],
            socials: [
                configDecode[0][5],// facebook
                configDecode[0][6],// twitterIcon
                configDecode[0][7],// telegramIcon
                configDecode[0][8],// githubIcon
                configDecode[0][9],// discordIcon
            ],
            primary_text_btn_color: configDecode[0][10] || defaultConfig.primary_text_btn_color,
            favicon: configDecode[0][11] || defaultConfig.favicon
        }
    } catch (error) {
        console.error('------_decodeConfig-------', error)
        return defaultConfig
    }
}
export const decodeConfig = _decodeConfig;

const _encodeConfig = (configDecode: { logo: string, sidebarLogo: string, primary: string, gradien_1: string, gradien_2: string, socials?: string[], primary_text_btn: string, favicon: string }) => {
    return ethers.utils.defaultAbiCoder.encode(
        ['string[]'],
        [
            [
                configDecode.logo || defaultConfig.logo,
                configDecode.sidebarLogo || defaultConfig.sidebarLogo,
                configDecode.primary || defaultConfig.primary,
                configDecode.gradien_1 || defaultConfig.gradien_1,
                configDecode.gradien_2 || defaultConfig.gradien_2,
                configDecode.socials && configDecode.socials[0] || '',
                configDecode.socials && configDecode.socials[1] || '',
                configDecode.socials && configDecode.socials[2] || '',
                configDecode.socials && configDecode.socials[3] || '',
                configDecode.socials && configDecode.socials[4] || '',
                configDecode.primary_text_btn || defaultConfig.primary_text_btn_color,
                configDecode.favicon || defaultConfig.favicon
            ]
        ]
    )
}
export const encodeConfig = _encodeConfig;