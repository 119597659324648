// eslint-disable-next-line no-unused-vars
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { branchState, initialState } from './type'
export const branch = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        setMainTokenInfo: (state, action: PayloadAction<{
            chainId: number,
            symbol: string,
            decimal: number
        }[]>) => {
            state.mainToken = action.payload
        },
        setCommissionContract: (state, action: PayloadAction<{
            chainId: number,
            commissionContractAddress: string,

        }[]>) => {
            state.commissionContractInfo = action.payload
        },
        setWallet: (state, action: PayloadAction<{ wallet: string }>) => {
            console.log('setWallet', action.payload.wallet)
            state.wallet = action.payload.wallet
        },
        setConfig: (state, action: PayloadAction<{ logo: string }>) => {
            console.log('setConfig', action.payload)
            state.config = action.payload
        }
    }
})

// Actions
export const {
    setMainTokenInfo,
    setWallet,
    setConfig,
    setCommissionContract
} = branch.actions

export default branch.reducer
