import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { ReactComponent as Icon } from './icon.svg'
import CONFIG from './config/config'
import { decodeConfig, encodeConfig, fetchConfigByBranch, useConfigAllChain } from 'App/Hooks/useReady'
import { fetchBranchId, getBranchId } from 'lib/legacy'
import { useOwnerOfBranch } from 'domain/branch'
// @ts-ignore
import { MdSwapVert } from 'react-icons/md'
import { BsFillGridFill } from 'react-icons/bs'
import { IoHomeSharp } from 'react-icons/io5'
// @ts-ignore
const svgText = ReactDOMServer.renderToString(<Icon />)
console.log(svgText)
export default {
  useOwnerOfBranch: useOwnerOfBranch,
  fetchConfig: fetchConfigByBranch,
  fetchBranch: fetchBranchId,
  encodeConfig: encodeConfig,
  decodeConfig: decodeConfig,
  otherParams: CONFIG,
  // Any component should be ok as long as it's props respect className which is resizable by CSS
  icon: (props: any) => (
    <img src={`data:image/svg+xml;base64,${btoa(svgText)}`} {...props} />
  ),
  name: 'One dex',
  path: '/trade',
  children: [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: (props) => {
        return <IoHomeSharp {...props} />
      }
    },
    {
      name: 'Trade',
      path: '/trade',
      icon: (props) => {
        return <MdSwapVert {...props} />
      }
    },
    {
      name: 'Buy',
      path: '/buy',
      icon: (props) => {
        return <BsFillGridFill {...props} />
      }
    },
    {
      name: 'Earn',
      path: '/earn',
      icon: (props) => {
        return <BsFillGridFill {...props} />
      }
    },
    // {
    //   name: 'Ecosystem',
    //   path: '/ecosystem'
    // },
    {
      name: 'Referrals',
      path: '/referrals',
      icon: (props) => {
        return <BsFillGridFill {...props} />
      }
    },
    {
      name: 'BuilDex',
      path: '/build_dex',
      icon: (props) => {
        return <BsFillGridFill {...props} />
      }
    }
  ]
}
