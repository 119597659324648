export interface branchState {
  mainToken: {
    symbol: string,
    decimal: number,
    chainId: number
  }[],
  commissionContractInfo: {
    chainId: number,
    commissionContractAddress: string
  }[]
  wallet: string,
  config: {
    logo: string,
    sideBarLogo?: string
  }
}

export const initialState: branchState = {
  mainToken: [],
  commissionContractInfo: [],
  wallet: "0x0000000000000000000000000000000000000000",
  config: {
    logo: '/icons/logo.svg'
  }
}
