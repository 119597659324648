
import { PERIOD } from 'lib/datafeed/historyProvider';
import { ARBITRUM } from './chains';
import CONFIGS from './config'

export const GMX_STATS_API_URL = CONFIGS.GMX_STATS_API_URL;
export const BACKEND_CORS_PROXY = CONFIGS.CHAINS[10].BACKEND_CORS_PROXY
const BACKEND_URLS = {
  default: "https://api.onedex.io/dex",
};
for (const name in CONFIGS.SUPPORTED_CHAIN_IDS) {
  let chaindId = CONFIGS.SUPPORTED_CHAIN_IDS[name]
  BACKEND_URLS[chaindId] = CONFIGS.CHAINS[chaindId].BACKEND_URLS
  BACKEND_URLS.default = CONFIGS.CHAINS[CONFIGS.DEFAULT_CHAIN_ID].BACKEND_URLS
}
export function getServerDomainUrl (){
  return CONFIGS.DOMAIN_URL
}

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    return BACKEND_URLS.default
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}
export function getResourceUrl(chainId) {
  let serverUrl = getServerBaseUrl(chainId)
  let { origin } = new URL(serverUrl)
  return origin
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}

export function getChartUrl(symbol: string, period: PERIOD, from: number, to?: number, chainId = 10) {
  let baseUrl = `${getServerUrl(chainId, '/candles')}`
  if (to) {
    return `${baseUrl}/?symbol=${symbol}&preferableChainId=${chainId}&period=${period}&from=${from}&to=${to}&preferableSource=fast`
  }
  return `${baseUrl}/?symbol=${symbol}&preferableChainId=${chainId}&period=${period}&from=${from}&preferableSource=fast`
}

