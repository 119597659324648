import { ethers } from "ethers";

export const REDIRECT_POPUP_TIMESTAMP_KEY = "redirect-popup-timestamp";

export const IS_TOUCH = "ontouchstart" in window;

export const UI_VERSION = "1.4";

export const TRIGGER_PREFIX_ABOVE = ">";
export const TRIGGER_PREFIX_BELOW = "<";

export const PRIMARY_COLOR = '#fcd535'
export const GRADIEN_START = '#fcd535'
export const GRADIEN_END = '#fcd535'

export const TOPIC_CONFIG = 'configs'

export const getTopicConfigs = () => ethers.utils.formatBytes32String(TOPIC_CONFIG)