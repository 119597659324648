import { ethers } from "ethers";
import { gql } from "@apollo/client";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Token as UniToken } from "@uniswap/sdk-core";
import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";

import OrderBook from "../abis/OrderBook.json";
import PositionManager from "../abis/PositionManager.json";
import Vault from "../abis/Vault.json";
import UniPool from "../abis/UniPool.json";
import UniswapV2 from "../abis/UniswapV2.json";
import WalletFactory from '../abis/WalletFactory.json'
import Wallet from '../abis/Wallet.json'
import Token from "../abis/Token.json";
import GMX from "../abis/GMT.json";
import StakingFactory from "../abis/StakingFactory.json";

import { getContract } from "../config/contracts";
import { ARBITRUM, AVALANCHE, getConstant, getHighExecutionFee, LOCAL_NETWORK, MULTIPLIER, SUPPORTED_CHAIN_IDS } from "../config/chains";
import { DECREASE, fetchBranchId, getBranchId, getOrderKey, INCREASE, isAddressZero, SWAP, USD_DECIMALS } from "../lib/legacy";

import { groupBy } from "lodash";
import { UI_VERSION } from "../config/ui";
import { getServerBaseUrl, getServerUrl } from "../config/backend";
import { getGmxGraphClient, TYPE } from "../lib/subgraph/clients";
import { callContract, contractFetcher } from "../lib/contracts";
import { replaceNativeTokenAddress } from "./tokens";
import { getUsd } from "./tokens/utils";
import { getProvider } from "../lib/rpc";
import { bigNumberify, expandDecimals, formatAmount, parseValue } from "../lib/numbers";
import { getToken, getTokenBySymbol } from "../config/tokens";
import { useCodeByReferrral, useUserReferralCode } from "./referrals";
import { contractMulticall } from 'lib/multicall/contractMulticallFetcher'
import { useChainId } from "lib/chains";
export * from "./prices";

const { AddressZero } = ethers.constants;

export function useAllOrdersStats(chainId) {
  const query = gql(`{
    orderStat(id: "total") {
      openSwap
      openIncrease
      openDecrease
      executedSwap
      executedIncrease
      executedDecrease
      cancelledSwap
      cancelledIncrease
      cancelledDecrease
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    const graphClient = getGmxGraphClient(chainId, TYPE.VAULT);
    graphClient?.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.orderStat : null;
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getGmxGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getGmxGraphClient(chainId);
      if (!graphClient) {
        return;
      }

      graphClient
        .query({ query })
        .then((res) => {
          const _data = res.data.liquidatedPositions.map((item) => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function useAllPositions(chainId, library) {
  const count = 1000;
  const query = gql(`{
    aggregatedTradeOpens(
      first: ${count}
    ) {
      account
      initialPosition{
        indexToken
        collateralToken
        isLong
        sizeDelta
      }
      increaseList {
        sizeDelta
      }
      decreaseList {
        sizeDelta
      }
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    const graphClient = getGmxGraphClient(chainId, TYPE.VAULT);
    graphClient?.query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  const key = res ? `allPositions${count}__` : false;
  const { data: positions = [] } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const ret = await Promise.all(
      res.data.aggregatedTradeOpens.map(async (dataItem) => {
        try {
          const { indexToken, collateralToken, isLong } = dataItem.initialPosition;
          const positionData = await contract.getPosition(dataItem.account, collateralToken, indexToken, isLong);
          const position = {
            size: bigNumberify(positionData[0]),
            collateral: bigNumberify(positionData[1]),
            entryFundingRate: bigNumberify(positionData[3]),
            account: dataItem.account,
          };
          position.fundingFee = await contract.getFundingFee(collateralToken, position.size, position.entryFundingRate);
          position.marginFee = position.size.div(1000);
          position.fee = position.fundingFee.add(position.marginFee);

          const THRESHOLD = 5000;
          const collateralDiffPercent = position.fee.mul(10000).div(position.collateral);
          position.danger = collateralDiffPercent.gt(THRESHOLD);

          return position;
        } catch (ex) {
          console.error(ex);
        }
      })
    );

    return ret.filter(Boolean);
  });

  return positions;
}

export function useAllOrders(chainId, library) {
  const query = gql(`{
    orders(
      first: 1000,
      orderBy: createdTimestamp,
      orderDirection: desc,
      where: {status: "open"}
    ) {
      type
      account
      index
      status
      createdTimestamp
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getGmxGraphClient(chainId).query({ query }).then(setRes);
  }, [setRes, query, chainId]);

  const key = res ? res.data.orders.map((order) => `${order.type}-${order.account}-${order.index}`) : null;
  const { data: orders = [] } = useSWR(key, () => {
    const provider = getProvider(library, chainId);
    const orderBookAddress = getContract(chainId, "OrderBook");
    const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, provider);
    return Promise.all(
      res.data.orders.map(async (order) => {
        try {
          const type = order.type.charAt(0).toUpperCase() + order.type.substring(1);
          const method = `get${type}Order`;
          const orderFromChain = await contract[method](order.account, order.index);
          const ret = {};
          for (const [key, val] of Object.entries(orderFromChain)) {
            ret[key] = val;
          }
          if (order.type === "swap") {
            ret.path = [ret.path0, ret.path1, ret.path2].filter((address) => address !== AddressZero);
          }
          ret.type = type;
          ret.index = order.index;
          ret.account = order.account;
          ret.createdTimestamp = order.createdTimestamp;
          return ret;
        } catch (ex) {
          console.error(ex);
        }
      })
    );
  });

  return orders.filter(Boolean);
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map((order) => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContract(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async (order) => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          console.error(ex);
        }
      })
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

export function useTrades(chainId, account, forSingleAccount, pageIndex = 0, pageLimit = 10) {
  let url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/tradeHistory?address=${account}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/actions`;
  const urlItem = new URL(url);
  urlItem.searchParams.append("limit", pageLimit);
  urlItem.searchParams.append("page", pageIndex + 1);
  url = urlItem.toString();

  const { data: res, mutate: updateTrades } = useSWR(url && url, {
    dedupingInterval: 10000,
    fetcher: (...args) => fetch(...args).then((res) => res.json()).then((res) => res?.data),
  });

  const { list: trades, page, limit, total } = res || { list: undefined }
  if (trades && trades?.length) {
    trades.sort((item0, item1) => {
      const data0 = item0.data;
      const data1 = item1.data;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }
  console.log('trades2', trades)
  return { trades: trades?.length ? trades : null, updateTrades, total, limit };
}
export function useMinPurchaseTokenAmountUsd(chainId) {
  const [data, setData] = useState(expandDecimals(10, 30))
  useEffect(() => {
    const orderBookAddress = getContract(chainId, 'OrderBook');
    const contractCall = contractFetcher(undefined, OrderBook, []);
    contractCall(`OrderBook:minPurchaseTokenAmountUsd()${chainId}`, chainId, orderBookAddress, "minPurchaseTokenAmountUsd").then(val => {
      setData(val.add(expandDecimals(1, USD_DECIMALS))) // Auto increase 1USD
    }).catch(console.error)
  }, [chainId])
  return [data]
}

export function useMinExecutionFee(library, active, chainId, infoTokens) {
  const orderBookAddress = getContract(chainId, "OrderBook");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");

  const { data: minExecutionFee } = useSWR([active, chainId, orderBookAddress, "minExecutionFee"], {
    fetcher: contractFetcher(library, OrderBook),
  });

  const { data: gasPrice } = useSWR(["gasPrice", chainId], {
    fetcher: () => {
      return new Promise(async (resolve, reject) => {
        const provider = getProvider(library, chainId);
        if (!provider) {
          resolve(undefined);
          return;
        }

        try {
          const gasPrice = await provider.getGasPrice();
          resolve(gasPrice);
        } catch (e) {
          console.error(e);
        }
      });
    },
  });


  // if gas prices on Arbitrum are high, the main transaction costs would come from the L2 gas usage
  // for executing positions this is around 65,000 gas
  // if gas prices on Ethereum are high, than the gas usage might be higher, this calculation doesn't deal with that
  // case yet
  let multiplier = MULTIPLIER[chainId] || 65000;

  let finalExecutionFee = minExecutionFee;

  if (gasPrice && minExecutionFee) {
    const estimatedExecutionFee = gasPrice.mul(multiplier);
    if (estimatedExecutionFee.gt(minExecutionFee)) {
      finalExecutionFee = estimatedExecutionFee;
    }
  }

  const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens);
  const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS));
  const errorMessage =
    isFeeHigh &&
    `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

  return {
    minExecutionFee: finalExecutionFee,
    minExecutionFeeUSD: finalExecutionFeeUSD,
    minExecutionFeeErrorMessage: errorMessage,
  };
}

export function useStakedGmxSupply(library, active) {
  const gmxAddressArb = getContract(ARBITRUM, "GMX");
  const stakedGmxTrackerAddressArb = getContract(ARBITRUM, "StakedGmxTracker");

  const { data: arbData, mutate: arbMutate } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, ARBITRUM, gmxAddressArb, "balanceOf", stakedGmxTrackerAddressArb],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const gmxAddressAvax = getContract(AVALANCHE, "GMX");
  const stakedGmxTrackerAddressAvax = getContract(AVALANCHE, "StakedGmxTracker");

  const { data: avaxData, mutate: avaxMutate } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, AVALANCHE, gmxAddressAvax, "balanceOf", stakedGmxTrackerAddressAvax],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  let data;
  if (arbData && avaxData) {
    data = arbData.add(avaxData);
  }

  const mutate = () => {
    arbMutate();
    avaxMutate();
  };

  return { data, mutate };
}

export function useHasOutdatedUi() {
  // const url = getServerUrl(ARBITRUM, "/ui_version");
  // const { data, mutate } = useSWR([url], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.text()),
  // });

  // let hasOutdatedUi = false;

  // if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
  //   hasOutdatedUi = true;
  // }

  return { data: false };
}

export function useGmxPrice(chainId, libraries, active) {
  // Hot Fake GMX PRICE
  let fakeGmxPrice = expandDecimals(10, 30);
  return {
    gmxPrice: fakeGmxPrice,
    gmxPriceFromArbitrum: fakeGmxPrice,
    gmxPriceFromAvalanche: fakeGmxPrice,
  };

  // const arbitrumLibrary = libraries && libraries.arbitrum ? libraries.arbitrum : undefined;
  // const { data: gmxPriceFromArbitrum, mutate: mutateFromArbitrum } = useGmxPriceFromArbitrum(arbitrumLibrary, active);
  // const { data: gmxPriceFromAvalanche, mutate: mutateFromAvalanche } = useGmxPriceFromAvalanche();

  // const gmxPrice = chainId === ARBITRUM ? gmxPriceFromArbitrum : gmxPriceFromAvalanche;
  // const mutate = useCallback(() => {
  //   mutateFromAvalanche();
  //   mutateFromArbitrum();
  // }, [mutateFromAvalanche, mutateFromArbitrum]);

  // return {
  //   gmxPrice,
  //   gmxPriceFromArbitrum,
  //   gmxPriceFromAvalanche,
  //   mutate,
  // };
}
export function useTotalGmxSupplyLocal() {
  const { data: gmxSupply, mutate: updateGmxSupply } = useSWR(
    [`GMX:totalSupply:${'LOCAL_NETWORK'}`, LOCAL_NETWORK, getContract(LOCAL_NETWORK, "GMX"), "totalSupply"],
    {
      fetcher: contractFetcher(undefined, GMX),
    }
  );

  return {
    total: gmxSupply ? bigNumberify(gmxSupply) : bigNumberify(0),
    mutate: updateGmxSupply,
  };
}
async function _fetchMainTokenSupplyAllChain() {
  try {
    const urls = SUPPORTED_CHAIN_IDS.map(chainId => `${getServerBaseUrl(chainId)}/gmx_supply?token=${getContract(chainId, 'GMX')}`)
    let tasks = urls.map(url => fetch(url).then(res => res.json()))
    let res = await Promise.all(tasks)
    let ret = res.map(res => res.data)
    return ret
  } catch (error) {
    console.warn('-----_fetchMainTokenSupplyAllChain-----', error)
    return [0]
  }
}

// use only the supply endpoint on arbitrum, it includes the supply on avalanche
export function useTotalGmxSupply() {
  const { data: gmxSupply, mutate: updateGmxSupply } = useSWR(`_fetchMainTokenSupplyAllChain`, {
    fetcher: (...args) => _fetchMainTokenSupplyAllChain().then(res => {
      return res
    }),
    initialData: []
  });
  const totalSupply = useMemo(() => {
    return gmxSupply.reduce((v, n) => {
      return v.add(n)
    }, bigNumberify(0))
  }, [gmxSupply])
  return {
    total: totalSupply ? totalSupply : undefined,
    mutate: updateGmxSupply,
  };
}

export async function _fetchTotalGmxStaked() {
  let promises = SUPPORTED_CHAIN_IDS.map((chainId) => {
    const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");

    if (stakedGmxTrackerAddress === AddressZero) return new Promise((r) => r(bigNumberify(0)));

    let fetch = contractFetcher(undefined, Token);
    return fetch(
      ...[
        `StakeV2:stakedGmxSupply:${chainId}`,
        chainId,
        getContract(chainId, "GMX"),
        "balanceOf",
        stakedGmxTrackerAddress,
      ]
    );
  });

  let totalStaked = await Promise.all(promises);

  let total = totalStaked.reduce((ret, val) => ret.add(val), bigNumberify(0));

  return {
    total,
    details: totalStaked,
  };
}

export function useTotalGmxStaked() {
  let totalStakedGmx = useRef(bigNumberify(0));

  const { data: total, mutate: updateStakedGmxSupply } = useSWR(["StakeV2:stakedGmxSupply:all"], {
    fetcher: _fetchTotalGmxStaked,
    initialData: {
      total: bigNumberify(0),
      details: SUPPORTED_CHAIN_IDS.map(() => bigNumberify(0)),
    },
  });
  const mutate = useCallback(() => {
    updateStakedGmxSupply();
  }, [updateStakedGmxSupply]);

  totalStakedGmx.current = total?.total || bigNumberify(0);

  let ret = { total: totalStakedGmx.current, details: [] };

  SUPPORTED_CHAIN_IDS.forEach((chainId, i) => {
    ret["details"].push({
      chainId,
      value: total.details[i],
    });
  });
  ret["mutate"] = mutate;
  return ret;
}

export function useTotalGmxInLiquidity() {
  // HARD CODE FAKE GMX
  let totalGMX = useRef(bigNumberify(0));
  return {
    avax: bigNumberify(0),
    arbitrum: bigNumberify(0),
    total: totalGMX.current,
    // mutate,
  };
  // let poolAddressArbitrum = getContract(ARBITRUM, "UniswapGmxEthPool");
  // let poolAddressAvax = getContract(AVALANCHE, "TraderJoeGmxAvaxPool");
  // let totalGMX = useRef(bigNumberify(0));

  // const { data: gmxInLiquidityOnArbitrum, mutate: mutateGMXInLiquidityOnArbitrum } = useSWR(
  //   [`StakeV2:gmxInLiquidity:${ARBITRUM}`, ARBITRUM, getContract(ARBITRUM, "GMX"), "balanceOf", poolAddressArbitrum],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const { data: gmxInLiquidityOnAvax, mutate: mutateGMXInLiquidityOnAvax } = useSWR(
  //   [`StakeV2:gmxInLiquidity:${AVALANCHE}`, AVALANCHE, getContract(AVALANCHE, "GMX"), "balanceOf", poolAddressAvax],
  //   {
  //     fetcher: contractFetcher(undefined, Token),
  //   }
  // );
  // const mutate = useCallback(() => {
  //   mutateGMXInLiquidityOnArbitrum();
  //   mutateGMXInLiquidityOnAvax();
  // }, [mutateGMXInLiquidityOnArbitrum, mutateGMXInLiquidityOnAvax]);

  // if (gmxInLiquidityOnAvax && gmxInLiquidityOnArbitrum) {
  //   let total = bigNumberify(gmxInLiquidityOnArbitrum).add(gmxInLiquidityOnAvax);
  //   totalGMX.current = total;
  // }
  // return {
  //   avax: gmxInLiquidityOnAvax,
  //   arbitrum: gmxInLiquidityOnArbitrum,
  //   total: totalGMX.current,
  //   mutate,
  // };
}

function useGmxPriceFromAvalanche() {
  const poolAddress = getContract(AVALANCHE, "TraderJoeGmxAvaxPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeGmxAvaxReserves", AVALANCHE, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: gmxReserve, _reserve1: avaxReserve } = data || {};

  const vaultAddress = getContract(AVALANCHE, "Vault");
  const avaxAddress = getTokenBySymbol(AVALANCHE, "WAVAX").address;
  const { data: avaxPrice, mutate: updateAvaxPrice } = useSWR(
    [`StakeV2:avaxPrice`, AVALANCHE, vaultAddress, "getMinPrice", avaxAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const PRECISION = bigNumberify(10).pow(18);
  let gmxPrice;
  if (avaxReserve && gmxReserve && avaxPrice) {
    gmxPrice = avaxReserve.mul(PRECISION).div(gmxReserve).mul(avaxPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateAvaxPrice(undefined, true);
  }, [updateReserves, updateAvaxPrice]);

  return { data: gmxPrice, mutate };
}

function useGmxPriceFromArbitrum(library, active) {
  const poolAddress = getContract(ARBITRUM, "UniswapGmxEthPool");
  const { data: uniPoolSlot0, mutate: updateUniPoolSlot0 } = useSWR(
    [`StakeV2:uniPoolSlot0:${active}`, ARBITRUM, poolAddress, "slot0"],
    {
      fetcher: contractFetcher(undefined, UniPool),
    }
  );

  const vaultAddress = getContract(ARBITRUM, "Vault");
  const ethAddress = getTokenBySymbol(ARBITRUM, "WETH").address;
  const { data: ethPrice, mutate: updateEthPrice } = useSWR(
    [`StakeV2:ethPrice:${active}`, ARBITRUM, vaultAddress, "getMinPrice", ethAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const gmxPrice = useMemo(() => {
    if (uniPoolSlot0 && ethPrice) {
      const tokenA = new UniToken(ARBITRUM, ethAddress, 18, "SYMBOL", "NAME");

      const gmxAddress = getContract(ARBITRUM, "GMX");
      const tokenB = new UniToken(ARBITRUM, gmxAddress, 18, "SYMBOL", "NAME");

      const pool = new Pool(
        tokenA, // tokenA
        tokenB, // tokenB
        10000, // fee
        uniPoolSlot0.sqrtPriceX96, // sqrtRatioX96
        1, // liquidity
        uniPoolSlot0.tick, // tickCurrent
        []
      );

      const poolTokenPrice = pool.priceOf(tokenB).toSignificant(6);
      const poolTokenPriceAmount = parseValue(poolTokenPrice, 18);
      return poolTokenPriceAmount.mul(ethPrice).div(expandDecimals(1, 18));
    }
  }, [ethPrice, uniPoolSlot0, ethAddress]);

  const mutate = useCallback(() => {
    updateUniPoolSlot0(undefined, true);
    updateEthPrice(undefined, true);
  }, [updateEthPrice, updateUniPoolSlot0]);

  return { data: gmxPrice, mutate };
}

export async function approvePlugin(
  chainId,
  pluginAddress,
  branchId,
  { library, pendingTxns, setPendingTxns, sentMsg, failMsg }
) {
  const walletFactoryAddress = getContract(chainId, "WalletFactory");
  const contract = new ethers.Contract(walletFactoryAddress, WalletFactory.abi, library.getSigner())
  return callContract(chainId, contract, "createWallet", [branchId, pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  });
}
export async function approvePluginWithCode(
  chainId,
  pluginAddress,
  code,
  branchId,
  { library, pendingTxns, setPendingTxns, sentMsg, failMsg }
) {
  const walletFactoryAddress = getContract(chainId, 'WalletFactory');
  const contract = new ethers.Contract(walletFactoryAddress, WalletFactory.abi, library.getSigner());
  return callContract(
    chainId,
    contract,
    "createWalletWithCode",
    [branchId, pluginAddress, code],
    {
      sentMsg,
      failMsg,
      pendingTxns,
      setPendingTxns,
    }
  );
}

export async function createSwapOrder(
  chainId,
  library,
  walletAddress,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  opts = {}
) {
  const executionFee = getConstant(chainId, "SWAP_ORDER_EXECUTION_GAS_FEE");
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee;

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap];

  const contract = new ethers.Contract(walletAddress, Wallet.abi, library.getSigner());

  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  walletAddress,
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = getConstant(chainId, "INCREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    path,
    amountIn,
    indexTokenAddress,
    minOut,
    sizeDelta,
    collateralTokenAddress,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    executionFee,
    shouldWrap,
  ];

  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }

  const contract = new ethers.Contract(walletAddress, Wallet.abi, library.getSigner());
  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  chainId,
  library,
  walletAddress,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  opts = {}
) {
  invariant(!isLong || indexTokenAddress === collateralTokenAddress, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const executionFee = getConstant(chainId, "DECREASE_ORDER_EXECUTION_GAS_FEE");

  const params = [
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
  ];
  opts.value = executionFee;
  const contract = new ethers.Contract(walletAddress, Wallet.abi, library.getSigner());

  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelSwapOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(opts.walletAddress, Wallet.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelDecreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(opts.walletAddress, Wallet.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(chainId, library, index, opts) {
  const params = [index];
  const method = "cancelIncreaseOrder";
  const orderBookAddress = getContract(chainId, "OrderBook");
  const contract = new ethers.Contract(opts.walletAddress, Wallet.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export function handleCancelOrder(chainId, library, order, opts) {
  let func;
  if (order.type === SWAP) {
    func = cancelSwapOrder;
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder;
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder;
  }

  return func(chainId, library, order.index, {
    successMsg: "Order cancelled.",
    failMsg: "Cancel failed.",
    sentMsg: "Cancel submitted.",
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
    walletAddress: opts.walletAddress
  });
}

export async function cancelMultipleOrders(chainId, library, allIndexes = [], opts) {
  const ordersWithTypes = groupBy(allIndexes, (v) => v.split("-")[0]);
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return;
    return ordersWithTypes[key].map((d) => d.split("-")[1]);
  }
  // params order => swap, increase, decrease
  const params = ["Swap", "Increase", "Decrease"].map((key) => getIndexes(key) || []);
  const method = "cancelMultiple";
  const contract = new ethers.Contract(opts.walletAccount, Wallet.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  chainId,
  library,
  index,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrder";
  const contract = new ethers.Contract(opts.walletAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  chainId,
  library,
  index,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts
) {
  const params = [index, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrder";
  const contract = new ethers.Contract(opts.walletAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(chainId, library, index, minOut, triggerRatio, triggerAboveThreshold, opts) {
  const params = [index, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrder";
  const contract = new ethers.Contract(opts.walletAddress, OrderBook.abi, library.getSigner());

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getContract(chainId, "PositionManager");
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library.getSigner());
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

export function useWalletContractAddress(chainId, library, account) {
  const walletFactoryAddress = getContract(chainId, 'WalletFactory');
  const branchId = useBranchId()

  return React.useMemo(() => {
    if (!account || !walletFactoryAddress || branchId == null) return
    const initCodeHash = ethers.utils.solidityKeccak256(['bytes'], [Wallet.bytecode])
    let salt = ethers.utils.solidityKeccak256(["uint256", "address"], [branchId, account])
    return ethers.utils.getCreate2Address(walletFactoryAddress, salt, initCodeHash)
  }, [account, branchId, walletFactoryAddress]);
}

export const useCreateWallet = () => {
  const branchId = useBranchId()

  const createWallet = useCallback(async (chainId, library, opts) => {
    let method = "createWallet";
    let params = [
      branchId
    ]
    const walletFactoryAddress = getContract(chainId, "WalletFactory");
    let walletFactory = new ethers.Contract(walletFactoryAddress, WalletFactory.abi, library.getSigner())
    let tx = await callContract(chainId, walletFactory, method, params, opts)
    opts.onSubmitTransaction && opts.onSubmitTransaction(tx)
  }, [])
  return [createWallet]
}
export const useNeedEnableOrder = (chainId, account, library, active, created, walletAddress) => {
  // Verify existence of wallet
  const branchId = useBranchId()
  const _isNeedCreateWallet = !created
  // Wallet verification added referral code
  const { attachedOnChain, userReferralCode } = useUserReferralCode(library, chainId, walletAddress);
  // Wallet verification added referral address
  const { code, localStorageReferral } = useCodeByReferrral(chainId, library)

  const needApprovalRefferalCode = !!(!attachedOnChain && userReferralCode);

  const needApproveReferralAddress = !!(!code && localStorageReferral)

  let needEnableOrder = _isNeedCreateWallet || needApprovalRefferalCode || needApproveReferralAddress
  const handleEnableOrder = useCallback((chainId, opts) => {
    let walletFactoryAddress = getContract(chainId, 'WalletFactory')

    let method = "createWallet";
    let params = [
      branchId
    ]
    if (needApprovalRefferalCode) {
      method = 'createWalletWithCode'
      params = [
        branchId,
        userReferralCode
      ]
    }
    if (needApproveReferralAddress) {
      method = 'createWalletWithRefAddress'
      params = [
        branchId,
        localStorageReferral
      ]
    }

    return callContract(chainId, new ethers.Contract(walletFactoryAddress, WalletFactory.abi, library?.getSigner()), method, params, opts)
  })
  return [needEnableOrder, handleEnableOrder]
}


export const useTotalRebates = (chainId, account, library, active, _walletAddress, isRef = true) => {

  const walletAddress = _walletAddress
  const commissionUrl = isRef ? getServerUrl(chainId, `/commissionRef?address=${walletAddress}`) : getServerUrl(chainId, `/commissionTrader?address=${walletAddress}`)

  const { data: commissions } = useSWR(walletAddress && [commissionUrl], {
    // @ts-ignore spread args incorrect type
    fetcher: (...args) => fetch(...args).then((res) => res.json()),
    refreshInterval: 500,
    refreshWhenHidden: true,
  });
  console.info(isRef ? "Ref" : "Trader", {
    commissions
  })
  return commissions?.data
}
export function useFetchBranchId(chainId) {
  const [data, setData] = useState(null)
  useEffect(() => {
    fetchBranchId(chainId).then(setData)
  }, [])
  return [data]
}
export function useBranchId() {
  const { chainId } = useChainId()

  return getBranchId(chainId)
}

export async function getContractTokenInfo(chainId, contractAddress) {
  let provider = getProvider(undefined, chainId)
  let contract = new ethers.Contract(contractAddress, Token.abi, provider)
  const taskSymbol = await contract.symbol();
  return {
    symbol: taskSymbol
  }
}

export function useContractTokenInfo(chainId, contractAddress) {
  const [data, setData] = useState()
  useEffect(() => {
    if (chainId && contractAddress && !isAddressZero(contractAddress)) {
      console.log('useContractTokenInfo', chainId, contractAddress)
      let contractCall = contractFetcher(undefined, Token, [])
      contractCall(`Token-${contractAddress}:symbol()${chainId}`, chainId, contractAddress, "symbol").then(symbol => {
        setData(symbol)
      })
    }
  }, [chainId, contractAddress])

  return [data]
}
export function useContractTokenInfoV2(chainId, contractAddress, allowETH = false) {
  const [data, setData] = useState({
    symbol: undefined,
    decimals: 18
  })
  useEffect(() => {
    if (chainId && contractAddress && allowETH && isAddressZero(contractAddress)) {
      let info = getToken(chainId, contractAddress)
      setData({
        symbol: info.symbol,
        decimals: info.decimals
      })
      return
    }
    if (chainId && contractAddress && !isAddressZero(contractAddress)) {
      try {
        let infoCache = getToken(chainId, contractAddress)
        setData({
          symbol: infoCache.symbol,
          decimals: infoCache.decimals
        })
        return
      } catch (error) {
        console.warn('fetch info from node')
      }
      console.log('useContractTokenInfo', chainId, contractAddress)
      let contractCall = contractMulticall(undefined, [Token.abi, Token.abi])

      contractCall(`Token-${contractAddress}:symbol()${chainId}`, chainId, [contractAddress, contractAddress], ["symbol", "decimals"], [[], []]).then(res => {
        let [[symbol], [decimals]] = res
        setData({
          symbol,
          decimals
        })
      })
    }
  }, [chainId, contractAddress])

  return data
}
export function useTrackingBalance(chainId, tokenAddress, account) {
  const { data: balance, mutate: updateEthPrice } = useSWR(
    !isAddressZero(tokenAddress) && [`Tracking:balanceOf(${account}):${chainId}-${tokenAddress}`, chainId, tokenAddress, "balanceOf", account],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );
  return [balance]
}

export function useTrackingBalances(chainId, tokenAddresss, accounts) {
  let accountsStr = JSON.stringify(accounts)
  let tokenAddresssStr = JSON.stringify(tokenAddresss)

  const { data: balances } = useSWR(
    !tokenAddresss?.includes(ethers.constants.AddressZero) && !accounts?.includes(ethers.constants.AddressZero) && [`Tracking:balance by mutilcall()`, chainId, accountsStr, tokenAddresssStr],
    {
      fetcher: (...args) => {
        let accounts = JSON.parse(args[2])
        let addresss = JSON.parse(args[3])
        let contractMulticallFetcher = contractMulticall(undefined, accounts.map(a => Token.abi))
        let methods = accounts.map(a => "balanceOf")
        return contractMulticallFetcher(`Tracking:balance by mutilcall(${args[2]},args[3])`, chainId, addresss, methods, accounts)
      },
      refreshInterval: 1000
    }
  );
  try {
    return balances?.map(b => b[0]) || []
  } catch (error) {
    console.error('Tracking:balance by mutilcall()------ERROR', error)
    return []
  }


}
let keys = [
  'gmxStakedDuration',
  'glpStakedDuration',
  'feeGmxStakedDuration',
  'feeGlpStakedDuration',
  'bonusMultiplier',
  'vestingDurationGMX',
  'vestingDurationGLP'
]
let defaultStakingInfo = keys.map(key => (bigNumberify(0)))

export function useEarnInfo(chainId, branchId) {
  const [data, setData] = useState({
    stakingInfo: defaultStakingInfo, stakingSets: []
  })
  useEffect(() => {
    if (!chainId || branchId == null) return
    let contractMulticallFetcher = contractMulticall(undefined, [StakingFactory.abi, StakingFactory.abi])
    const stakingFactoryAddress = getContract(chainId, 'StakingFactory')
    chainId && branchId != null && contractMulticallFetcher(
      `Fetch earn info of ${branchId}`,
      chainId,
      [stakingFactoryAddress, stakingFactoryAddress],
      ['getStakingInfo', 'stakingSets'], [[branchId], [branchId]]
    ).then(([[stakingInfo], [stakingSets]]) => {
      setData({
        stakingInfo,
        stakingSets
      })
    })
  }, [chainId, branchId])
  const [mainSymbol] = useContractTokenInfo(chainId, data?.stakingSets?.length ? data?.stakingSets[0] : null)
  return [
    data.stakingInfo || defaultStakingInfo,
    data.stakingSets || [],
    mainSymbol || '--'
  ]
}

export function useAddCommissionRewardData(chainId, rewardCommissionToken, commissionContract, account) {
  const [data, setData] = useState([
    bigNumberify(0),
    bigNumberify(0),
    {
      symbol: '--',
      decimals: 18
    }
  ])
  useEffect(() => {
    if (!chainId || rewardCommissionToken == null || isAddressZero(rewardCommissionToken) || commissionContract == null || isAddressZero(commissionContract) || account == null || isAddressZero(account)) return

    let contractMulticallFetcher = contractMulticall(undefined, [
      Token.abi,
      Token.abi,
      Token.abi,
      Token.abi,
    ])
    contractMulticallFetcher(`useAddCommissionRewardData---${rewardCommissionToken}+${commissionContract}+${account}`,
      chainId,
      [
        rewardCommissionToken,
        rewardCommissionToken,
        rewardCommissionToken,
        rewardCommissionToken
      ],
      ['balanceOf', 'balanceOf', 'symbol', 'decimals'],
      [[account], [commissionContract], [], []]
    ).then(([[maxBalance], [rewardAmount], [symbol], [decimals]]) => {
      setData([
        maxBalance,
        rewardAmount,
        {
          symbol,
          decimals
        }
      ])
    })
  }, [chainId, rewardCommissionToken, commissionContract, account])
  return { maxBalance: data[0], rewardAmount: data[1], rewardTokenInfo: data[2] }
}
