import { getContract } from "./contracts";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, LOCAL_NETWORK, OP } from "./chains";
import { Token } from "domain/tokens";
import { assign } from 'lodash'
import CONFIGS from './config'

export const STAKE_NAMES_CONTRACT = CONFIGS.STAKE_NAMES_CONTRACT || []

export const TOKENS: { [chainId: number]: Token[] } = {};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {};

export const GLP_POOL_COLORS = {};

// for (const chainId in CONFIGS.CHAINS) {
//   // let tokens = CONFIGS.CHAINS[chainId].TOKENS
//   // TOKENS[chainId] = tokens.map((tk) => ({
//   //   ...tk,
//   //   address: tk.address || getContract(Number(chainId), tk.symbol)
//   // }))
//   // console.info({
//   //   TOKENS
//   // })
//   // let platformTokens = CONFIGS.CHAINS[chainId].PLATFORM_TOKENS
//   // PLATFORM_TOKENS[chainId] = platformTokens.reduce((ret, tk) => {
//   //   ret[tk.symbol] = { ...tk, address: getContract(Number(chainId), tk.symbol) }
//   //   return ret
//   // }, {})

//   assign(GLP_POOL_COLORS, CONFIGS.GLP_POOL_COLORS)
// }
assign(GLP_POOL_COLORS, CONFIGS.GLP_POOL_COLORS)

export const ICONLINKS = {
  [ARBITRUM_TESTNET]: {
    GMX: {
      coingecko: "#",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://testnet.arbiscan.io/token/0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    },
  },
  [OP]: {
    GMX: {
      arbitrum: "#",
    },
    GLP: {
      arbitrum: "#",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      op: "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000006"
    },
    OP: {
      coingecko: "https://www.coingecko.com/en/coins/optimism",
      op: "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000042"
    },
    WETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      op: "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000006"
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/token/0x68f180fcce6836688e9084f035309e29bf0a2095"
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      op: "https://optimistic.etherscan.io/token/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      op: "https://optimistic.etherscan.io/token/0x6fd9d7ad17242c41f7131d257212c54a0e816691",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      op: "https://optimistic.etherscan.io/token/0x7f5c764cbc14f9669b88837ca1490cca17c31607",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      op: "https://optimistic.etherscan.io/token/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      op: "https://optimistic.etherscan.io/token/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      op: "https://optimistic.etherscan.io/token/0xb153fb3d196a8eb25522705560ac152eeec57901",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      op: "https://optimistic.etherscan.io/token/0x2e3d870790dc77a83dd1d18184acc7439a53f475",
    },
  },
  [ARBITRUM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "#",
    },
    GLP: {
      arbitrum: "#",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "#",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "#",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "#",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "#",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "#",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "#",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "#",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "#",
    },
  },
  [AVALANCHE]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      avalanche: "#",
    },
    GLP: {
      avalanche: "#",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "#",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "#",
    },
    "BTC.b": {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "#",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "#",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "#",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "#",
    },
  },
  [LOCAL_NETWORK]: {
    GMX: {
      arbitrum: "#",
    },
    GLP: {
      arbitrum: "#",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
      op: "https://optimistic.etherscan.io/token/0x4200000000000000000000000000000000000006"
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      op: "https://optimistic.etherscan.io/token/0x68f180fcce6836688e9084f035309e29bf0a2095"
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      op: "https://optimistic.etherscan.io/token/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      op: "https://optimistic.etherscan.io/token/0x6fd9d7ad17242c41f7131d257212c54a0e816691",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      op: "https://optimistic.etherscan.io/token/0x7f5c764cbc14f9669b88837ca1490cca17c31607",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      op: "https://optimistic.etherscan.io/token/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      op: "https://optimistic.etherscan.io/token/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      op: "https://optimistic.etherscan.io/token/0xb153fb3d196a8eb25522705560ac152eeec57901",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      op: "https://optimistic.etherscan.io/token/0x2e3d870790dc77a83dd1d18184acc7439a53f475",
    },
  },
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS: any[] = [];
for (const chainName in CONFIGS.SUPPORTED_CHAIN_IDS) {
  CHAIN_IDS.push(CONFIGS.SUPPORTED_CHAIN_IDS[chainName])
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return _getOrCreateToken(chainId).filter((token) => token.symbol !== "USDG");
}

export function getWhitelistedCommissionTokens(chainId) {
  return CONFIGS.CHAINS[chainId]['COMMISSIONS_WHITELIST_TOKEN']
}
export function getWhitelistedBranchTokens(chainId) {
  return CONFIGS.CHAINS[chainId]['BRANCH_WHITELIST_TOKEN']
}


export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}
function _updateTokenMap(chainId) {
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}
function _updateWrappedTokenMaps(chainId) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}
export function updateTokens(chainId) {
  let tokens = CONFIGS.CHAINS[chainId].TOKENS
  TOKENS[chainId] = tokens.map((tk) => ({
    ...tk,
    address: tk.address || getContract(Number(chainId), tk.symbol)
  }))
}
export const updateTokenMap = _updateTokenMap
export const updateWrappedTokenMaps = _updateWrappedTokenMaps

export function updateFlatformTokens(chainId) {
  try {
    let tokens = CONFIGS.CHAINS[chainId].TOKENS
    TOKENS[chainId] = tokens.map((tk) => ({
      ...tk,
      address: tk.address || getContract(Number(chainId), tk.symbol)
    }))

    let platformTokens = CONFIGS.CHAINS[chainId].PLATFORM_TOKENS
    PLATFORM_TOKENS[chainId] = platformTokens.reduce((ret, tk) => {
      ret[tk.symbol] = { ...tk, address: getContract(Number(chainId), tk.symbol) }
      return ret
    }, {})

    assign(GLP_POOL_COLORS, CONFIGS.GLP_POOL_COLORS)


    _updateTokenMap(chainId)
    _updateWrappedTokenMaps(chainId)

  } catch (error) {
    throw Error(`updateFlatformTokens:${chainId} ${error.toString()}`)
  }
}
export function isStable(chainId, symbol) {
  return TOKENS_BY_SYMBOL_MAP[chainId][symbol].isStable
}

function _getOrCreateToken(chainId) {
  if (TOKENS[chainId]) return TOKENS[chainId]
  let tokens = CONFIGS.CHAINS[chainId].TOKENS
  TOKENS[chainId] = tokens.map((tk) => ({
    ...tk,
    address: tk.address || getContract(Number(chainId), tk.symbol)
  }))
  return TOKENS[chainId];
}
